var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-card-title',[_vm._v(" Verfügbarkeit "),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer')],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.slots,"items-per-page":20,"item-key":"name","dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.monday",fn:function(ref){
var item = ref.item;
return [(item.monday === 'Active')?[_c('v-chip',{attrs:{"color":"success","label":"","small":"","text-color":"success","outlined":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-check ")]),_vm._v(" Verfügbar ")],1)]:_vm._e(),(item.monday === 'Deactive')?[_c('v-chip',{attrs:{"color":"danger","label":"","small":"","text-color":"danger","outlined":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-close ")]),_vm._v(" nicht Verfügbar ")],1)]:_vm._e(),(item.monday === 'Maybe')?[_c('v-chip',{attrs:{"color":"orange","label":"","small":"","text-color":"orange","outlined":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-close ")]),_vm._v(" Wahrscheinlich ")],1)]:_vm._e()]}},{key:"item.tuesday",fn:function(ref){
var item = ref.item;
return [(item.tuesday === 'Active')?[_c('v-chip',{attrs:{"color":"success","label":"","small":"","text-color":"success","outlined":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-check ")]),_vm._v(" Verfügbar ")],1)]:_vm._e(),(item.tuesday === 'Deactive')?[_c('v-chip',{attrs:{"color":"danger","label":"","small":"","text-color":"danger","outlined":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-close ")]),_vm._v(" nicht Verfügbar ")],1)]:_vm._e(),(item.tuesday === 'Maybe')?[_c('v-chip',{attrs:{"color":"orange","label":"","small":"","text-color":"orange","outlined":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-close ")]),_vm._v(" Wahrscheinlich ")],1)]:_vm._e()]}},{key:"item.wednesday",fn:function(ref){
var item = ref.item;
return [(item.wednesday === 'Active')?[_c('v-chip',{attrs:{"color":"success","label":"","small":"","text-color":"success","outlined":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-check ")]),_vm._v(" Verfügbar ")],1)]:_vm._e(),(item.wednesday === 'Deactive')?[_c('v-chip',{attrs:{"color":"danger","label":"","small":"","text-color":"danger","outlined":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-close ")]),_vm._v(" nicht Verfügbar ")],1)]:_vm._e(),(item.wednesday === 'Maybe')?[_c('v-chip',{attrs:{"color":"orange","label":"","small":"","text-color":"orange","outlined":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-exclamation ")]),_vm._v(" Wahrscheinlich ")],1)]:_vm._e()]}},{key:"item.thursday",fn:function(ref){
var item = ref.item;
return [(item.thursday === 'Active')?[_c('v-chip',{attrs:{"color":"success","label":"","small":"","text-color":"success","outlined":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-check ")]),_vm._v(" Verfügbar ")],1)]:_vm._e(),(item.thursday === 'Deactive')?[_c('v-chip',{attrs:{"color":"danger","label":"","small":"","text-color":"danger","outlined":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-close ")]),_vm._v(" nicht Verfügbar ")],1)]:_vm._e(),(item.thursday === 'Maybe')?[_c('v-chip',{attrs:{"color":"orange","label":"","small":"","text-color":"orange","outlined":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-close ")]),_vm._v(" Wahrscheinlich ")],1)]:_vm._e()]}},{key:"item.friday",fn:function(ref){
var item = ref.item;
return [(item.friday === 'Active')?[_c('v-chip',{attrs:{"color":"success","label":"","small":"","text-color":"success","outlined":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-check ")]),_vm._v(" Verfügbar ")],1)]:_vm._e(),(item.friday === 'Deactive')?[_c('v-chip',{attrs:{"color":"danger","label":"","small":"","text-color":"danger","outlined":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-close ")]),_vm._v(" nicht Verfügbar ")],1)]:_vm._e(),(item.friday === 'Maybe')?[_c('v-chip',{attrs:{"color":"orange","label":"","small":"","text-color":"orange","outlined":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-exclamation ")]),_vm._v(" Wahrscheinlich ")],1)]:_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }