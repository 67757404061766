<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          Verfügbarkeit
          <v-spacer />
          <v-spacer />
          <v-spacer />
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="slots"
            :items-per-page="20"
            item-key="name"
            class="elevation-1 table-one"
            dense
            hide-default-footer
          >
            <template v-slot:item.monday="{item}">
              <template v-if="item.monday === 'Active'">
                <v-chip
                  class=""
                  color="success"
                  label
                  small
                  text-color="success"
                  outlined
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-check
                  </v-icon>
                  Verfügbar
                </v-chip>
              </template>
              <template v-if="item.monday === 'Deactive'">
                <v-chip
                  class=""
                  color="danger"
                  label
                  small
                  text-color="danger"
                  outlined
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-close
                  </v-icon>
                  nicht Verfügbar
                </v-chip>
              </template>
              <template v-if="item.monday === 'Maybe'">
                <v-chip
                  class=""
                  color="orange"
                  label
                  small
                  text-color="orange"
                  outlined
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-close
                  </v-icon>
                  Wahrscheinlich
                </v-chip>
              </template>
            </template>
            <template v-slot:item.tuesday="{item}">
              <template v-if="item.tuesday === 'Active'">
                <v-chip
                  class=""
                  color="success"
                  label
                  small
                  text-color="success"
                  outlined
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-check
                  </v-icon>
                  Verfügbar
                </v-chip>
              </template>
              <template v-if="item.tuesday === 'Deactive'">
                <v-chip
                  class=""
                  color="danger"
                  label
                  small
                  text-color="danger"
                  outlined
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-close
                  </v-icon>
                  nicht Verfügbar
                </v-chip>
              </template>
              <template v-if="item.tuesday === 'Maybe'">
                <v-chip
                  class=""
                  color="orange"
                  label
                  small
                  text-color="orange"
                  outlined
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-close
                  </v-icon>
                  Wahrscheinlich
                </v-chip>
              </template>
            </template>
            <template v-slot:item.wednesday="{item}">
              <template v-if="item.wednesday === 'Active'">
                <v-chip
                  class=""
                  color="success"
                  label
                  small
                  text-color="success"
                  outlined
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-check
                  </v-icon>
                  Verfügbar
                </v-chip>
              </template>
              <template v-if="item.wednesday === 'Deactive'">
                <v-chip
                  class=""
                  color="danger"
                  label
                  small
                  text-color="danger"
                  outlined
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-close
                  </v-icon>
                  nicht Verfügbar
                </v-chip>
              </template>
              <template v-if="item.wednesday === 'Maybe'">
                <v-chip
                  class=""
                  color="orange"
                  label
                  small
                  text-color="orange"
                  outlined
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-exclamation
                  </v-icon>
                  Wahrscheinlich
                </v-chip>
              </template>
            </template>
            <template v-slot:item.thursday="{item}">
              <template v-if="item.thursday === 'Active'">
                <v-chip
                  class=""
                  color="success"
                  label
                  small
                  text-color="success"
                  outlined
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-check
                  </v-icon>
                  Verfügbar
                </v-chip>
              </template>
              <template v-if="item.thursday === 'Deactive'">
                <v-chip
                  class=""
                  color="danger"
                  label
                  small
                  text-color="danger"
                  outlined
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-close
                  </v-icon>
                  nicht Verfügbar
                </v-chip>
              </template>
              <template v-if="item.thursday === 'Maybe'">
                <v-chip
                  class=""
                  color="orange"
                  label
                  small
                  text-color="orange"
                  outlined
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-close
                  </v-icon>
                  Wahrscheinlich
                </v-chip>
              </template>
            </template>
            <template v-slot:item.friday="{item}">
              <template v-if="item.friday === 'Active'">
                <v-chip
                  class=""
                  color="success"
                  label
                  small
                  text-color="success"
                  outlined
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-check
                  </v-icon>
                  Verfügbar
                </v-chip>
              </template>
              <template v-if="item.friday === 'Deactive'">
                <v-chip
                  class=""
                  color="danger"
                  label
                  small
                  text-color="danger"
                  outlined
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-close
                  </v-icon>
                  nicht Verfügbar
                </v-chip>
              </template>
              <template v-if="item.friday === 'Maybe'">
                <v-chip
                  class=""
                  color="orange"
                  label
                  small
                  text-color="orange"
                  outlined
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-exclamation
                  </v-icon>
                  Wahrscheinlich
                </v-chip>
              </template>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Table One',
    },
    data () {
      return {
        search: '',
        selected: [],
        headers: [
          {
            text: 'Zeit',
            align: 'start',
            sortable: false,
            value: 'time',
          },
          { text: 'Montag', value: 'monday' },
          { text: 'Dienstag', value: 'tuesday' },
          { text: 'Mittwoch', value: 'wednesday' },
          { text: 'Donnerstag', value: 'thursday' },
          { text: 'Freitag', value: 'friday' },
        ],
        slots: [
          {
            img: require('@/assets/images/avatars/001-man.svg'),
            time: '07:00 - 08:00',
            monday: 'Deactive',
            tuesday: 'Active',
            wednesday: 'Maybe',
            thursday: 'Active',
            friday: 'Deactive',
          },
          {
            img: require('@/assets/images/avatars/002-woman.svg'),
            time: '08:00 - 09:00',
            monday: 'Deactive',
            tuesday: 'Active',
            wednesday: 'Maybe',
            thursday: 'Active',
            friday: 'Deactive',
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            time: '09:00 - 10:00',
            monday: 'Deactive',
            tuesday: 'Active',
            wednesday: 'Maybe',
            thursday: 'Active',
            friday: 'Deactive',
          },
          {
            img: require('@/assets/images/avatars/004-bald.svg'),
            time: '10:00 - 11:00',
            monday: 'Deactive',
            tuesday: 'Active',
            wednesday: 'Maybe',
            thursday: 'Active',
            friday: 'Deactive',
          },
          {
            img: require('@/assets/images/avatars/007-woman-2.svg'),
            time: '11:00 - 12:00',
            monday: 'Deactive',
            tuesday: 'Active',
            wednesday: 'Maybe',
            thursday: 'Active',
            friday: 'Deactive',
          },
          {
            img: require('@/assets/images/avatars/001-man.svg'),
            time: '12:00 - 13:00',
            monday: 'Deactive',
            tuesday: 'Active',
            wednesday: 'Maybe',
            thursday: 'Active',
            friday: 'Deactive',
          },
          {
            img: require('@/assets/images/avatars/002-woman.svg'),
            time: '13:00 - 14:00',
            monday: 'Deactive',
            tuesday: 'Active',
            wednesday: 'Maybe',
            thursday: 'Active',
            friday: 'Deactive',
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            time: '14:00 - 15:00',
            monday: 'Deactive',
            tuesday: 'Active',
            wednesday: 'Maybe',
            thursday: 'Active',
            friday: 'Maybe',
          },
          {
            img: require('@/assets/images/avatars/004-bald.svg'),
            time: '15:00 - 16:00',
            monday: 'Deactive',
            tuesday: 'Active',
            wednesday: 'Maybe',
            thursday: 'Active',
            friday: 'Maybe',
          },
          {
            img: require('@/assets/images/avatars/007-woman-2.svg'),
            time: '16:00 - 17:00',
            monday: 'Deactive',
            tuesday: 'Active',
            wednesday: 'Maybe',
            thursday: 'Active',
            friday: 'Maybe',
          },
          {
            img: require('@/assets/images/avatars/002-woman.svg'),
            time: '17:00 - 18:00',
            monday: 'Deactive',
            tuesday: 'Active',
            wednesday: 'Maybe',
            thursday: 'Active',
            friday: 'Maybe',
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            time: '19:00 - 20:00',
            monday: 'Deactive',
            tuesday: 'Active',
            wednesday: 'Maybe',
            thursday: 'Active',
            friday: 'Maybe',
          },
          {
            img: require('@/assets/images/avatars/004-bald.svg'),
            time: '20:00 - 21:00',
            monday: 'Deactive',
            tuesday: 'Active',
            wednesday: 'Maybe',
            thursday: 'Active',
            friday: 'Maybe',
          },
        ],
      }
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
